import Layout from '@/layout';
var currencyRouter = {
  path: '/currency',
  component: Layout,
  redirect: '/currency/setting',
  name: 'Currency',
  meta: {
    title: '币币管理',
    icon: 's-check',
    roles: ['admin']
  },
  children: [{
    path: 'order',
    name: 'order',
    component: function component() {
      return import('@/views/currency/order/index');
    },
    meta: {
      title: '订单管理'
    }
  }, {
    path: 'setting',
    name: 'setting',
    component: function component() {
      return import('@/views/currency/setting/index');
    },
    meta: {
      title: '币币设置'
    }
  }, {
    path: 'robot',
    name: 'robot',
    component: function component() {
      return import('@/views/currency/robot/index');
    },
    meta: {
      title: '控盘机器人'
    }
  }]
};
export default currencyRouter;